import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

import Billboard from 'components/Billboard/Billboard';
import { useGlobalStore } from 'store';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './LandingBillboard.styles';

type LandingBillboardPropTypes = {
  copy: string;
  animationCallback?: () => void;
};

const LandingBillboard = ({ copy }: LandingBillboardPropTypes) => {
  const { isReady } = useGlobalStore(state => state);
  const [speed, setSpeed] = useState(0);
  const container = useRef(null);
  const slash = useRef(null);
  const timeline = useRef<gsap.core.Timeline>(null);

  useEffect(() => {
    const containerEl = container.current;
    const slashEl = slash.current;

    gsap.set(containerEl, { autoAlpha: 0 });
    if (!containerEl || !slashEl || !isReady) return;

    timeline.current = gsap
      .timeline({ paused: false, delay: 0.5 })
      .set(containerEl, { autoAlpha: 1 })
      .fromTo(
        containerEl,
        {
          x: '-180vw',
        },
        {
          x: '0',
          duration: 0.8,
          ease: 'power2.out',
          immediateRender: false,
        }
      )
      .fromTo(
        slashEl,
        {
          scaleX: 50,
          yPercent: -50,
        },
        {
          scaleX: 0,
          yPercent: -50,
          duration: 0.5,
          ease: 'power3.out',
        },
        '-=0.4'
      )
      .call(
        () => {
          setSpeed(100);
        },
        null,
        1
      );
  }, [slash, container, isReady]);

  useEffect(() => {
    return () => {
      setSpeed(0);
      if (timeline.current) timeline.current.kill();
    };
  }, []);

  return (
    <Styled.Wrapper ref={container}>
      <Styled.WarpedBillboard>
        <Styled.InnerWrapper>
          <Billboard
            isScrolling={true}
            copy={copy}
            textColor={ColorNames.white}
            speed={speed}
            mobileRows={1}
          />
        </Styled.InnerWrapper>
        <Styled.ExpandingSlash ref={slash} />
      </Styled.WarpedBillboard>
    </Styled.Wrapper>
  );
};

export default React.memo(LandingBillboard);
