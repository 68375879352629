import styled from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  position:relative;
  transform: translateX(-180vw);
  width: 100%;
`;

export const WarpedBillboard = styled.div`
  overflow: hidden;
`;

export const InnerWrapper = styled.div`
  ${mediaTablet(`
    transform: translateY(24rem);
  `)}
`

export const ExpandingSlash = styled.div`
  background: white;
  position:absolute;
  top: 50%;
  bottom: 0;
  height: 60%;
  width: 36rem;
  right: -40rem;
  transform: skew(-35deg) translateY(-50%) scaleX(50);
  transform-origin: 0 50%;
  ${mediaTablet(`
    top: calc(50% + 24rem);
  `)}
`;
