import React, { MutableRefObject, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import * as Styled from './Lottie.styles';

export interface LottieProps {
  animation: any;
  autoPlay?: boolean;
  style?: Record<string, any>;
  useCanvas?: boolean;
  pauseAtFrame?: number;
  forceContinue?: boolean;
  onLoad?: () => void;
  onReady?: () => void;
  onComplete?: () => void;
  onLoadError?: () => void;
  onFrame?: (props?: any) => void;
}

const defaultProps: Partial<LottieProps> = {
  autoPlay: true,
  style: {},
  useCanvas: false,
  pauseAtFrame: null,
  forceContinue: false,
  onLoad: () => null,
  onReady: () => null,
  onComplete: () => null,
  onLoadError: () => null,
  onFrame: () => null
};

const Lottie = React.forwardRef<Player, Partial<LottieProps>>(
  ({
    animation,
    autoPlay,
    useCanvas,
    pauseAtFrame,
    forceContinue,
    style,
    onLoad,
    onReady,
    onComplete,
    onLoadError,
    onFrame
  }: LottieProps, lottiePlayer?: MutableRefObject<Player>) => {

    const handleOnFrame = () => {
      if (pauseAtFrame && lottiePlayer.current.state.instance) {
        if (lottiePlayer.current.state.instance['currentFrame'] >= pauseAtFrame && !forceContinue) {
          lottiePlayer.current.pause();
        }
      }
      onFrame();
    };

    useEffect(() => {
      if (forceContinue) {
        lottiePlayer.current.play();
      }
    }, [forceContinue, lottiePlayer]);

    const handleEvent = (event) => {
      if (lottiePlayer) {
        switch (event) {
          case 'load': onLoad(); break;
          case 'error': onLoadError(); break;
          case 'ready': onReady(); break;
          case 'frame': handleOnFrame(); break;
          case 'complete': onComplete(); break;
          default: break;
        }
      }
    };

    return (
      <Styled.Wrapper>
        <Player
          ref={lottiePlayer}
          src={animation}
          autoplay={autoPlay}
          loop={false}
          keepLastFrame={true}
          style={style}
          onEvent={handleEvent}
          renderer={useCanvas ? 'canvas' : 'svg'}
        />
      </Styled.Wrapper>
    );
  }
);

Lottie.displayName = 'Lottie';
Lottie.defaultProps = defaultProps;

export default Lottie;
