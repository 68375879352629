import React, { useImperativeHandle, useMemo, useRef } from 'react';

import { LANDING_IMAGES } from 'utils/config.assets';
import PreloaderService from 'u9/services/preloader.service';
import useMotion from './LandingImages.motion';
import * as Styled from './LandingImages.styles';

export interface LandingImagesRef {
  inTl: gsap.core.Timeline;
  outTl: gsap.core.Timeline;
}

export interface LandingImagesProps { }

const defaultProps: Partial<LandingImagesProps> = {};

const LandingImages = React.forwardRef<LandingImagesRef, Partial<LandingImagesProps>>(
  (props, ref) => {
    const images = useRef([]);

    const { inTl, outTl } = useMotion({ imageRefs: images });

    const imageComponents = useMemo(() => {
      return LANDING_IMAGES.map(
        (image, index) => {
          return (
            <Styled.Image
              ref={ref => (images.current[index] = ref)}
              key={`BackingImage-${index}`}
              image={PreloaderService.get(image.src)}
              top={image.top}
              left={image.left}
              scale={image.scale}
            />
          );
        }
      );
    }, []);

    useImperativeHandle(
      ref,
      (): LandingImagesRef => ({
        inTl: inTl.current,
        outTl: outTl.current
      })
    )

    return (
      <Styled.Wrapper>
        {imageComponents}
      </Styled.Wrapper>
    );
  }
);

LandingImages.defaultProps = defaultProps;

export default LandingImages;
