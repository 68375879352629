import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { isStorybook } from 'u9/utils/platform';

export interface MotionProps {
  imageRefs?: any
}

const useMotion = ({
  imageRefs
}: MotionProps) => {
  const inTl = useRef<gsap.core.Timeline>(null);
  const outTl = useRef<gsap.core.Timeline>(null);

  // animate in
  useEffect(() => {
    const images = imageRefs.current;

    if (!inTl.current && images?.length > 0) {
      inTl.current = gsap.timeline({ paused: !isStorybook() });
      inTl.current
        .to(
          [
            images[0],
            images[2],
            images[1]
          ],
          {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
            duration: 1,
            ease: 'expo.inOut',
            stagger: 0.2
          }
        )
        .to(
          images[0],
          { y: '+=1500px', duration: 100, ease: 'none' },
          0
        )
        .to(
          images[2],
          { x: '+=1500px', duration: 100, ease: 'none' },
          0
        )
        .to(
          images[1],
          { x: '+=1500px', duration: 100, ease: 'none' },
          0
        );

      outTl.current = gsap.timeline({ paused: true });
      outTl.current
        .to(
          [
            images[0],
            images[2],
            images[1]
          ],
          {
            clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)',
            duration: 0.8,
            ease: 'expo.inOut',
            stagger: 0.2
          }
        );
    }
  }, [imageRefs]);

  useEffect(() => {
    return () => {
      if (inTl.current) {
        inTl.current.kill();
      }

      if (outTl.current) {
        outTl.current.kill();
      }
    };
  }, []);

  return { inTl, outTl };
};

export default useMotion;
