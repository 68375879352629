import styled from 'styled-components';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div<{ justifyContent?: string; }>`
  position: relative;
  display: flex;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
  align-items: center;
  left: 100%;
  opacity: 0;
`;

export const Copy = styled.div``;

export const Slash = styled.div<{ skewDeg?: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110%;
  height: 100%;
  background-color: ${colors.white};
  transform: skew(${(props) => props.skewDeg}deg, 0) translate(-50%, -50%);
`;
