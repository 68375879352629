import styled from 'styled-components';

import { fullscreen } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const MainImage = styled.div<{ background: string }>`
  ${fullscreen()}
  background-image: url(${props => props.background});
  background-position: center;
  background-size: cover;
`;

export const SequencedImagesWrapper = styled.div`
  ${fullscreen()}
`;

export const SequencedImage = styled.div<{ background: string, zIndex: number }>`
  ${fullscreen()}
  background-image: url(${(props) => props.background});
  background-position: center;
  background-size: cover;
  z-index: ${props => props.zIndex};
`;

export const WhiteFlash = styled.div`
  ${fullscreen()}
  background-color: ${colors.white};
  opacity: 0;
  z-index: ${layout.zIndex.clubhouseSelectorWhiteFlash};
`;
