import { useEffect, useRef } from 'react';
import gsap from 'gsap';

const useMotion = ({
  mainImage,
  sequencedImages,
  whiteFlash,
  hovered
}) => {
  const inTl = useRef<gsap.core.Timeline>(null);
  const hoverTl = useRef<gsap.core.Timeline>(null);
  const unhoverTl = useRef<gsap.core.Timeline>(null);
  const previouslyHovered = useRef<boolean>(false);

  const killHoverTimelines = () => {
    if (hoverTl.current) hoverTl.current.kill();
    if (unhoverTl.current) unhoverTl.current.kill();
  };

  const animateIn = () => {
    if (inTl.current) inTl.current.kill();

    inTl.current = gsap.timeline({
      onComplete: () => {
        inTl.current = null;
      }
    });
    inTl.current
      .fromTo(
        mainImage.current,
        { scale: 1.4, opacity: 0 },
        { scale: 1, opacity: 1, duration: 1, ease: 'power4.inOut' },
      );

    return inTl.current;
  }

  const hover = () => {
    killHoverTimelines();

    const duration = 0.3;
    previouslyHovered.current = true;
    hoverTl.current = gsap.timeline({
      repeat: -1,
      onComplete: () => {
        hoverTl.current = null;
      }
    });
    sequencedImages.forEach((image, index) => {
      hoverTl.current
        .fromTo(
          whiteFlash,
          { opacity: 0.3 },
          {
            opacity: 0,
            duration: duration,
          },
          index * duration,
        )
        .set(
          image,
          { opacity: 1 },
          '<0.016'
        )
    })
  };

  const unhover = () => {
    killHoverTimelines();

    unhoverTl.current = gsap.timeline({
      onComplete: () => {
        unhoverTl.current = null;
      }
    });

    unhoverTl.current.to(
      [whiteFlash, sequencedImages],
      { opacity: 0, duration: 0.3, ease: 'power4.inOut' }
    );
  };

  // set sequenced images initial opacity
  useEffect(() => {
    if (sequencedImages) {
      gsap.set(
        sequencedImages,
        { opacity: 0 }
      );
    }

    if (mainImage) {
      gsap.set(
        mainImage.current,
        { opacity: 1 }
      );
    }
  }, [whiteFlash, mainImage, sequencedImages]);

  // animate on hover
  useEffect(() => {
    if (hovered) {
      hover();
    } else {
      // prevent calling this function on first render
      previouslyHovered.current && unhover();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  // clean up timelines
  useEffect(() => {
    return () => {
      if (inTl.current) inTl.current.kill();
      killHoverTimelines();
    }
  }, []);

  return { animateIn };
};

export default useMotion;
