import React, { MutableRefObject, useImperativeHandle, useRef } from 'react';

import useMotion from './LandingBackground.motion';
import * as Styled from './LandingBackground.styles';

export interface LandingBackgroundRef {
  inTl: gsap.core.Timeline;
  outTl: gsap.core.Timeline;
}

export interface LandingBackgroundProps {
  loaderRef: MutableRefObject<HTMLDivElement>;
  billboardWrapperRef: MutableRefObject<HTMLDivElement>;
  progress: number;
}

const defaultProps: Partial<LandingBackgroundProps> = {};

const LandingBackground = React.forwardRef<LandingBackgroundRef, Partial<LandingBackgroundProps>>(
  ({ loaderRef, billboardWrapperRef }, ref) => {
    const wrapper = useRef<HTMLDivElement>(null);
    const black = useRef<HTMLDivElement>(null);
    const white = useRef<HTMLDivElement>(null);
    const blue = useRef<HTMLDivElement>(null);

    const { inTl, outTl } = useMotion(
      {
        wrapperRef: wrapper,
        blackRef: black,
        whiteRef: white,
        blueRef: blue,
        loaderRef,
        billboardWrapperRef
      }
    );

    useImperativeHandle(
      ref,
      (): LandingBackgroundRef => ({
        inTl: inTl.current,
        outTl: outTl.current
      })
    );

    return (
      <Styled.Wrapper ref={wrapper}>
        <Styled.Black ref={black} />
        <Styled.White ref={white} />
        <Styled.Blue ref={blue} />
      </Styled.Wrapper>
    );
  }
);

LandingBackground.defaultProps = defaultProps;

export default LandingBackground;
