import React, { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import { useRouter } from 'next/router';
import { capitalize } from 'lodash';

import { ClubhousePageProps } from 'containers/ClubhousePage/ClubhousePage';
import AudioManager from 'services/audioManager/audioManager.service';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import { useCopyStore, useGlobalStore, useClubhouseStore } from 'store';
import { BlackBarTransitionState, BlackBarPositionState } from 'store/global.types';
import { CLUBHOUSE_SELECTOR_HEADING_WIDTH } from 'utils/config';
import { ROUTES } from 'utils/routes';
import gtm from 'u9/utils/gtm';

import ClubhouseSelectorHeading, { ClubhouseSelectorHeadingRef } from './ClubhouseSelectorHeading/ClubhouseSelectorHeading';
import ClubhouseSelectorImages, { ClubhouseSelectorImagesRef } from './ClubhouseSelectorImages/ClubhouseSelectorImages';
import useMotion from './ClubhouseSelector.motion';
import * as Styled from './ClubhouseSelector.styles';

interface ClubhouseSelectorProps {
  onClubhouseHovered?: (clubhouse: ClubhousePageProps['city']) => void
}

const defaultProps = {
  onClubhouseHovered: () => null
};

const ClubhouseSelector = ({
  onClubhouseHovered
}: ClubhouseSelectorProps) => {
  const timeline = useRef<gsap.core.Timeline>(null);
  const clubhouseSelectorHeadings = useRef<ClubhouseSelectorHeadingRef[]>([]);
  const clubhouseSelectorImages = useRef<ClubhouseSelectorImagesRef[]>([]);

  const { experience: { landingPage: { clubhouses } } } = useCopyStore(state => state.copy);
  const [hoveredIndex, setHoveredIndex] = useState<number>(null);
  const { setBlackBarTransition, isReady } = useGlobalStore();
  const { hasClubhousePreloaded } = useClubhouseStore();

  // Add fallback for Storybook testing
  const router = useRouter() || { locale: 'en', push: () => null };

  const playSelectSFX = () => {
    const proceed = () => {
      AudioManager.play(SOUNDS_DATA.selectClubhouse);
    };

    if (AudioManager.isUnlocked) proceed();
    else if (AudioManager.cache[SOUNDS_DATA.selectClubhouse.id]) {
      AudioManager.cache[SOUNDS_DATA.selectClubhouse.id].sound.once('unlock', proceed);
    }
  };

  const handleClubhouseHovered = (clubhouse: ClubhousePageProps['city'], index: number) => {
    if (timeline.current) return;

    setHoveredIndex(index);
    onClubhouseHovered(clubhouse);
    AudioManager.play(SOUNDS_DATA.clubhouseHover);
  };

  const handleClubhouseUnhovered = () => {
    if (timeline.current) return;

    setHoveredIndex(null);
    onClubhouseHovered(null);
    AudioManager.stop(SOUNDS_DATA.clubhouseHover, 0.2);
  };

  const handleClubhouseSelect = (city: ClubhousePageProps['city']) => {
    AudioManager.stop(SOUNDS_DATA.clubhouseHover);
    playSelectSFX();

    const clubhouseName = city === 'seoul' ? 'LCK' : city === 'berlin' ? 'LEC' : 'LPL';
    setBlackBarTransition(BlackBarTransitionState.In, BlackBarPositionState.Ribbon, BlackBarPositionState.Cover, async () => {
      gtm.trackEvent('Landing - Clubhouses', `Click - ${clubhouseName}`, `${clubhouseName} - ${capitalize(city)} Clubhouse - Select`);

      await router.push(ROUTES.CLUBHOUSE.replace('[city]', city));
      // if the loader has already triggered before then animate out to the edges;
      // otherwise the animation code is continued on ClubhouseLoadingOverlay
      if (hasClubhousePreloaded[city]) {
        setBlackBarTransition(BlackBarTransitionState.Out, BlackBarPositionState.Cover, BlackBarPositionState.Center);
      }
    })
  };

  const { getFramerMotionProps } = useMotion();

  const animateIn = () => {
    if (timeline.current) timeline.current.kill();

    timeline.current = gsap.timeline({
      onComplete: () => {
        timeline.current = null;
      }
    });

    clubhouseSelectorHeadings.current.forEach(
      (heading, i) => {
        timeline.current.call(() => {
          heading.lottie.play();
        }, null, i === 0 ? '0.5' : '+=0.36')
      }
    )
    clubhouseSelectorImages.current.forEach(
      image => timeline.current.add(image.animateIn(), 0.1)
    )

    return timeline.current;
  };

  useEffect(() => {
    if (isReady) {
      animateIn();
    } else if (clubhouseSelectorImages.current.length) {
      animateIn().pause(0);
    }
  }, [isReady, router.locale, clubhouseSelectorImages.current.length]);

  useEffect(() => {
    return () => {
      if (timeline.current) timeline.current.kill();
    }
  }, []);

  const clubhouseComponents = Object.keys(clubhouses).map((clubhouseKey: ClubhousePageProps['city'], index) => {
    const headingShouldFade = hoveredIndex !== null && hoveredIndex !== index;
    return (
      <Styled.Clubhouse
        key={`Clubhouse-${index}`}
        onMouseEnter={() => handleClubhouseHovered(clubhouseKey, index)}
        onClick={() => handleClubhouseSelect(clubhouseKey)}
        {...getFramerMotionProps(hoveredIndex, index)}
      >
        <ClubhouseSelectorImages
          ref={(ref: ClubhouseSelectorImagesRef) => clubhouseSelectorImages.current[index] = ref}
          clubhouse={clubhouseKey}
          hovered={hoveredIndex !== null && hoveredIndex === index}
        />
        <Styled.DarkOverlay />
        <Styled.HeadingWrapper
          responsiveWidth={
            CLUBHOUSE_SELECTOR_HEADING_WIDTH[router.locale][clubhouseKey]
          }
        >
          <ClubhouseSelectorHeading
            ref={(ref: ClubhouseSelectorHeadingRef) => clubhouseSelectorHeadings.current[index] = ref}
            city={clubhouseKey as ClubhousePageProps['city']}
            country={clubhouses[clubhouseKey].country}
            faded={headingShouldFade}
          />
        </Styled.HeadingWrapper>
      </Styled.Clubhouse>
    );
  });

  return (
    <Styled.Wrapper>
      <Styled.Clubhouses onMouseLeave={handleClubhouseUnhovered}>
        {clubhouseComponents}
      </Styled.Clubhouses>
    </Styled.Wrapper>
  );
};

ClubhouseSelector.defaultProps = defaultProps;

export default ClubhouseSelector;
