import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import Lottie from 'components/Lottie/Lottie';
import { isMobileLayout } from 'utils/styles/responsive';
import { useCopyStore } from 'store';
import { LOTTIE_FILES } from 'utils/config.assets';
import * as Styled from './ClubhouseSelectorHeading.styles';
import { Player } from '@lottiefiles/react-lottie-player';

export interface ClubhouseSelectorHeadingRef {
  lottie: Player;
}

export interface ClubhouseSelectorHeadingProps {
  city: string;
  country: string;
  faded?: boolean;
}

const defaultProps = {
  city: '',
  country: '',
  faded: false
};

const ClubhouseSelectorHeading = React.forwardRef(
  ({
    city,
    country,
    faded
  }: ClubhouseSelectorHeadingProps, ref) => {
    const { experience: { footer: { languages: languagesCopy } } } = useCopyStore(state => state.copy);
    const languages = useMemo(() => {
      return languagesCopy.map((lang) => lang.code);
    }, [languagesCopy]);

    // const motionTimeout = useRef(null);
    const lottieRef = useRef<Player>(null);
    const [useDOM, setUseDOM] = useState(false);

    // Add fallback for Storybook testing
    const router = useRouter() || { locale: languages[0], push: () => null };
    const lottieTitle = isMobileLayout() ?
      LOTTIE_FILES[router.locale].landing.clubhouseTitle.mobile[city.toLowerCase()] :
      LOTTIE_FILES[router.locale].landing.clubhouseTitle.desktop[city.toLowerCase()];

    const handleLottieLoadError = () => {
      // use DOM as a fallback if Lottie fails to load
      setUseDOM(true);
    };

    useImperativeHandle(
      ref,
      (): ClubhouseSelectorHeadingRef => ({
        lottie: lottieRef.current
      })
    );

    return (
      <Styled.Wrapper faded={faded}>
        {
          useDOM ?
            (
              <Styled.DOMWrapper>
                <Styled.Heading>
                  {city}
                </Styled.Heading>
                <Styled.Bottom>
                  {
                    !isMobileLayout() &&
                    <Styled.BottomText>
                      {country}
                    </Styled.BottomText>
                  }
                  <Styled.Dash />
                </Styled.Bottom>
              </Styled.DOMWrapper>
            ) : (
              <Styled.LottieWrapper>
                <Lottie
                  ref={lottieRef}
                  animation={lottieTitle}
                  autoPlay={false}
                  onLoadError={handleLottieLoadError}
                />
              </Styled.LottieWrapper>
            )
        }
      </Styled.Wrapper>
    );
  }
);

ClubhouseSelectorHeading.displayName = 'ClubhouseSelectorHeading';
ClubhouseSelectorHeading.defaultProps = defaultProps;

export default ClubhouseSelectorHeading;
