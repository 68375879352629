import styled from 'styled-components';
import { fullscreen } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  ${fullscreen()}
  clip-path: inset(0 0 0 0);
  z-index: 3;
  pointer-events: none;
`;

export const Black = styled.div`
  ${fullscreen()}
  background-color: ${colors.black};
  z-index: 1;
`;

export const White = styled.div`
  ${fullscreen()}
  background-color: ${colors.white};
  width: 350%;
  height: 200%;
  top: unset;
  left: unset;
  bottom: -200%;
  right: 150%;
  transform-origin: center;
  transform: rotate(-45);
  z-index: 2;

  ${mediaTablet(`
    width: 200%;
    height: 100%;
    bottom: -100%;
    right: 50%;
  `)}
`;

export const Blue = styled.div`
  ${fullscreen()}
  background-color: ${colors.blueRibbon};
  width: 350%;
  height: 200%;
  top: -200%;
  left: 150%;
  bottom: unset;
  right: unset;
  transform-origin: center;
  transform: rotate(-45);
  z-index: 3;

  ${mediaTablet(`
    width: 200%;
    height: 100%;
    top: -100%;
    left: 50%;
  `)}
`;
