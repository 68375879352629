import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Player } from '@lottiefiles/react-lottie-player';

import { useGlobalStore, useLoaderStore } from 'store';
import { isStorybook } from 'u9/utils/platform';
import { StretchedCopyRef } from 'components/StretchedCopy/StretchedCopy';
import { LandingImagesRef } from './LandingImages/LandingImages';
import { LandingMobileLogosRef } from './LandingMobileLogos/LandingMobileLogos';
import { LandingProgressRef } from './LandingProgress/LandingProgress';
import { LandingBackgroundRef } from './LandingBackground/LandingBackground';

interface MotionProps {
  spotifyPresents: Player;
  uncover: Player;
  spotifyPresentsWrapper: HTMLDivElement;
  uncoverWrapper: HTMLDivElement;
  mobileLogos: LandingMobileLogosRef;
  introHeader: StretchedCopyRef;
  progress: LandingProgressRef;
  images: LandingImagesRef;
  background: LandingBackgroundRef;
}

const useMotion = ({
  spotifyPresents,
  spotifyPresentsWrapper,
  uncover,
  uncoverWrapper,
  mobileLogos,
  introHeader,
  progress,
  images,
  background
}: MotionProps) => {
  const [continueUncoverLottie, setContinueUncoverLottie] = useState<boolean>(false);
  const [hasAnimatedIn, setAnimatedIn] = useState<boolean>(false);
  const { isReady, setLoaderMotionComplete } = useGlobalStore(state => state);
  const globalLoadProgress = useLoaderStore(state => state.globalLoadProgress);

  // refs
  const inTl = useRef<gsap.core.Timeline>(null);
  const outTl = useRef<gsap.core.Timeline>(null);

  useEffect(() => {
    // animate in
    if (!isReady && !inTl.current && spotifyPresents && spotifyPresentsWrapper && uncover && mobileLogos.timeline && progress.inTl && background && images.inTl) {
      inTl.current = gsap.timeline({
        paused: !isStorybook()
      });

      inTl.current
        .add(background.inTl.play(), 0)
        .addLabel('showLogo', 1.2)
        .call(() => spotifyPresents.play(), null, 'showLogo')
        .to(
          spotifyPresentsWrapper,
          { opacity: 0, duration: 0, ease: 'none' },
          'showLogo+=3.2'
        )
        .add(introHeader.animateIn(), 'showLogo+=3.5')
        .add(progress.inTl.play(), 'showLogo+=3.5')
        .add(images.inTl.play(), 'showLogo+=3.5')
        .call(() => uncover.play(), null, 'showLogo+=3.5')
        .fromTo(
          uncoverWrapper,
          { opacity: 0 },
          { opacity: 1, duration: 1, ease: 'power4.inOut' },
          'showLogo+=3'
        );
      if (mobileLogos.timeline) {
        inTl.current.add(mobileLogos.timeline.play(), 'showLogo+=3')
      }

      inTl.current.call(() => setAnimatedIn(true), null, 'showLogo+=6');
      inTl.current.play();
    }
  }, [
    spotifyPresents,
    spotifyPresentsWrapper,
    uncover,
    uncoverWrapper,
    mobileLogos,
    introHeader,
    progress,
    images,
    isReady,
    background
  ]);

  useEffect(() => {
    // animate out
    if (hasAnimatedIn && uncoverWrapper && uncover && globalLoadProgress >= 100) {
      if (outTl.current) {
        if (!outTl.current.isActive()) {
          outTl.current.kill();
        } else {
          // Return if already playing the out animation
          return;
        }
      }
      outTl.current = gsap.timeline();
      outTl.current
        .add(background.outTl.play(), 1)
        .add(images.outTl.play(), 0)
        .call(() => setContinueUncoverLottie(true), null, 0)
        .add(progress.outTl.play(), 0)
        .to(
          uncoverWrapper,
          { opacity: 0, duration: 0.5, ease: 'power4.inOut' },
          2
        )
        .call(() => setLoaderMotionComplete(true), null, 2);
    }
  }, [hasAnimatedIn, uncoverWrapper, uncover, globalLoadProgress, images, background, progress, setLoaderMotionComplete]);

  useEffect(() => {
    if (isReady && introHeader && background?.inTl && background?.outTl) {
      introHeader.animateIn();
      background.inTl.progress(1);
      background.outTl.progress(1);
    }
  }, [background, introHeader, isReady]);

  useEffect(() => {
    if (inTl.current) {
      inTl.current.kill();
    }

    return () => {
      if (inTl.current) {
        inTl.current.kill();
      }

      if (outTl.current) {
        outTl.current.kill();
      }
    };
  }, []);

  return { continueUncoverLottie, inTl, outTl };
};

export default useMotion;
