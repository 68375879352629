import React, { useRef, useEffect, useImperativeHandle } from 'react';
import gsap from 'gsap';

import * as Styled from './StretchedCopy.styles';

export interface StretchedCopyRef {
  animateIn: () => gsap.core.Timeline;
}

export interface StretchedCopyProps {
  copy?: string;
  autoPlayMotion?: boolean;
  justifyContent?: string;
  slashLeftOffset?: string;
}

const defaultProps: Partial<StretchedCopyProps> = {
  copy: '',
  autoPlayMotion: false,
  justifyContent: 'center',
  slashLeftOffset: '50%',
};

const StretchedCopy = React.forwardRef<StretchedCopyRef, StretchedCopyProps>(
  (
    { copy, autoPlayMotion, justifyContent, slashLeftOffset }: StretchedCopyProps,
    ref
  ) => {
    const timeline = useRef<gsap.core.Timeline>(null);
    const wrapper = useRef<HTMLDivElement>(null);
    const slash = useRef<HTMLDivElement>(null);
    const skewDeg = -25;

    const animateIn = () => {
      if (timeline.current) timeline.current.kill();

      timeline.current = gsap.timeline({
        onComplete: () => {
          timeline.current = null;
        }
      });
      timeline.current
        .fromTo(
          wrapper.current,
          { opacity: 0 },
          { opacity: 1, duration: 0.01, ease: 'none' }
        )
        .to(
          wrapper.current,
          { left: 0, duration: 1, ease: 'expo.inOut' },
          0.1
        )
        .to(
          slash.current,
          { scaleX: 0, left: slashLeftOffset, duration: 0.7, ease: 'expo.inOut' },
          0.35
        );

      return timeline.current;
    };

    useEffect(() => {
      if (autoPlayMotion) {
        animateIn();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoPlayMotion]);

    useEffect(() => {
      return () => {
        if (timeline.current) timeline.current.kill();
      }
    }, []);

    useImperativeHandle(
      ref,
      (): StretchedCopyRef => ({
        animateIn
      })
    );

    return (
      <Styled.Wrapper ref={wrapper} justifyContent={justifyContent}>
        <Styled.Copy>
          {copy}
        </Styled.Copy>
        <Styled.Slash ref={slash} skewDeg={skewDeg} />
      </Styled.Wrapper>
    );
  });

StretchedCopy.displayName = 'StretchedCopy';
StretchedCopy.defaultProps = defaultProps;

export default StretchedCopy;
