const useMotion = () => {
  const animationVariants = {
    hovered: {
      width: 'calc(100% / 1.5)',
      transition: {
        duration: 0.2,
        type: 'tween',
        ease: 'easeInOut'
      }
    },
    unhovered: { width: 'calc(100% / 3)' }
  }

  const getFramerMotionProps = (hoveredIndex: number, index: number) => {
    return {
      variants: animationVariants,
      initial: 'unhovered',
      animate: hoveredIndex === index ? 'hovered' : 'unhovered',
      custom: index
    }
  };

  return {
    getFramerMotionProps
  }
};

export default useMotion;
