import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colors, layout } from 'utils/styles/theme';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { isIOS, isSafari, isTablet } from 'u9/utils/platform';

export interface ClubhouseProps {
  background: string;
}

export interface ResponsiveWidth {
  desktop?: number;
  tablet?: number;
  mobile?: number;
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Clubhouses = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Clubhouse = styled(motion.div)`
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: width 0.2s ease-out;
  overflow: hidden;
`;

export const DarkOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${colors.black};
  opacity: 0.4;
  z-index: 1;
`;

export const HeadingWrapper = styled.div<{ responsiveWidth?: ResponsiveWidth }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${props => props.responsiveWidth.mobile}rem;
  transform: translate(-50%, -50%);
  ${(isIOS() && !isTablet()) ? 'margin-top: -90%;' : ''}
  ${(isSafari() && isTablet()) ? 'margin-top: -30%;' : ''}
  z-index: ${layout.zIndex.clubhouseSelectorHeading};;

  ${(props) => `
    ${mediaTablet(`
      width: ${props.responsiveWidth.tablet}rem;
    `)}

    ${mediaDesktop(`
      width: ${props.responsiveWidth.desktop}rem;
    `)}
  `}

`;
