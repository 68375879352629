import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { GetStaticProps } from 'next';

import Ribbon from 'components/Ribbon/Ribbon';
import ClubhouseSelector from 'components/ClubhouseSelector/ClubhouseSelector';
import LandingBillboard from 'components/LandingBillboard/LandingBillboard';
import LandingLoader from 'components/LandingLoader/LandingLoader';

import { useCopyStore, usePlaylistsStore } from 'store';
import { SharedCopy } from 'store/copy.types';
import { HeadProps } from 'u9/components/Head/Head';
import { getApiTrack, getTrackFromApiTrack } from 'utils/api';
import { cmsApiClient } from 'utils/cms/api';
import { landingPageQuery } from 'utils/cms/gql';
import { ISR_TIMEOUT } from 'utils/config';
import { ANTHEM_TRACK } from 'utils/config.assets';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './LandingPage.styles';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const result = await cmsApiClient.query({
    query: landingPageQuery({ locale }),
  });
  const { sharedCopy, landingPage: page } = result.data;
  if (!page || !sharedCopy) return { notFound: true };

  const { head } = page;

  return {
    props: {
      head,
      sharedCopy,
    },
    revalidate: ISR_TIMEOUT,
  };
};

interface LandingPageProps {
  head: HeadProps;
  sharedCopy: SharedCopy;
}

const LandingPage: React.FunctionComponent<LandingPageProps> = ({ head, sharedCopy }) => {
  const [billboardCopy, setBillboardCopy] = useState<string>('');
  const [ribbonCopy, setRibbonCopy] = useState<string>('');
  const { copy, setCopy } = useCopyStore((state) => state);
  const { anthemTrack, setAnthemTrack, addTrackToFavorites } = usePlaylistsStore(state => state);
  const billboardWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCopy({ head, global: sharedCopy.global, experience: sharedCopy.experience });
    setBillboardCopy(sharedCopy.experience.landingPage.billboard);
    setRibbonCopy(sharedCopy.experience.landingPage.ribbon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [head, sharedCopy]);

  const getAnthemTrack = useCallback(async () => {
    const { data: apiTrack } = await getApiTrack(ANTHEM_TRACK.id);
    const track = getTrackFromApiTrack({
      ...apiTrack,
      // The CMS hardcoded url is used as fallback
      preview_url: apiTrack.preview_url || ANTHEM_TRACK.previewUrl
    }, true);

    addTrackToFavorites(track);
    setAnthemTrack(track);
  }, [addTrackToFavorites, setAnthemTrack]);

  useEffect(() => {
    if (!anthemTrack) getAnthemTrack();
  }, [anthemTrack, getAnthemTrack]);

  if (!copy.global || !copy.experience) return null;

  const { landingPage: pageCopy } = copy.experience;
  if (!pageCopy) return null;

  const { experience: { landingPage: {
    billboard,
    clubhouses,
    ribbon,
    ribbon_instruction
  } } } = copy;

  const handleClubhouseHovered = (clubhouse: string) => {
    if (clubhouse !== null) {
      setBillboardCopy(clubhouses[clubhouse].billboard);
      setRibbonCopy(clubhouses[clubhouse].ribbon);
    } else {
      setBillboardCopy(billboard);
      setRibbonCopy(ribbon);
    }
  };

  return (
    <>
      <Styled.BlankPage/>
      <Styled.BillboardWrapper ref={billboardWrapperRef}>
        <LandingBillboard copy={billboardCopy} />
      </Styled.BillboardWrapper>
      <Styled.RibbonWrapper>
        <Ribbon
          copy={ribbonCopy}
          message={ribbon_instruction}
          iconColor={ColorNames.white}
          textColor={ColorNames.roti}
        />
      </Styled.RibbonWrapper>
      <Styled.ClubhouseSelectorWrapper>
        <ClubhouseSelector
          onClubhouseHovered={handleClubhouseHovered}
        />
      </Styled.ClubhouseSelectorWrapper>
      <LandingLoader billboardWrapperRef={billboardWrapperRef} />
    </>
  );
};

export default LandingPage;
