import { useMemo } from 'react';

import { CLUBHOUSE_SELECTOR_IMAGES } from 'utils/config.assets';
import PreloaderService from 'u9/services/preloader.service';

const useAssets = (clubhouse) => {
  return useMemo(() => {
    const images = {
      main: PreloaderService.get(CLUBHOUSE_SELECTOR_IMAGES[clubhouse].main),
      sequence: [
        ...CLUBHOUSE_SELECTOR_IMAGES[clubhouse].sequence.map((img) => PreloaderService.get(img))
      ]
    }

    return images;
  }, [clubhouse]);
};

export default useAssets;
