import React, { MutableRefObject, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { Player } from '@lottiefiles/react-lottie-player';

// components
import Lottie from 'components/Lottie/Lottie';
import StretchedCopy, { StretchedCopyRef } from 'components/StretchedCopy/StretchedCopy';
import LandingBackground, { LandingBackgroundRef } from 'components/LandingLoader/LandingBackground/LandingBackground';
import LandingImages, { LandingImagesRef } from 'components/LandingLoader/LandingImages/LandingImages';
import LandingProgress, { LandingProgressRef } from 'components/LandingLoader/LandingProgress/LandingProgress';
import LandingMobileLogos, { LandingMobileLogosRef } from 'components/LandingLoader/LandingMobileLogos/LandingMobileLogos';

// utils
import { useCopyStore, useGlobalStore } from 'store';

// assets
import { LOTTIE_FILES } from 'utils/config.assets';

import useMotion from './LandingLoader.motion';
import * as Styled from './LandingLoader.styles';

// interfaces
export interface LandingLoaderProps {
  billboardWrapperRef: MutableRefObject<HTMLDivElement>;
}

const defaultProps: Partial<LandingLoaderProps> = {};

const LandingLoader: React.FC<LandingLoaderProps> = ({ billboardWrapperRef }) => {
  const { isLoaderMotionComplete } = useGlobalStore(state => state);

  // Add fallback for Storybook testing
  const router = useRouter() || { locale: 'en', push: () => null };

  // refs
  const wrapperRef = useRef<HTMLDivElement>(null);
  const spotifyPresentsRef = useRef<Player>(null);
  const spotifyPresentsWrapperRef = useRef<HTMLDivElement>(null);
  const uncoverRef = useRef<Player>(null);
  const uncoverWrapperRef = useRef<HTMLDivElement>(null);
  const mobileLogosRef = useRef<LandingMobileLogosRef>(null);
  const introHeaderWrapperRef = useRef<HTMLDivElement>(null);
  const introHeaderRef = useRef<StretchedCopyRef>(null);
  const progressRef = useRef<LandingProgressRef>(null);
  const imagesRef = useRef<LandingImagesRef>(null);
  const backgroundRef = useRef<LandingBackgroundRef>(null);

  // store
  const { experience: { landingPage: { header } } } = useCopyStore(state => state.copy);

  const lottieFiles = useMemo(() => {
    return {
      spotifyPresents: LOTTIE_FILES[router.locale].landing.spotifyPresents,
      uncover: LOTTIE_FILES[router.locale].landing.uncover
    };
  }, [router.locale]);

  const { continueUncoverLottie } = useMotion({
    spotifyPresents: spotifyPresentsRef.current,
    spotifyPresentsWrapper: spotifyPresentsWrapperRef.current,
    uncover: uncoverRef.current,
    uncoverWrapper: uncoverWrapperRef.current,
    mobileLogos: mobileLogosRef.current,
    introHeader: introHeaderRef.current,
    progress: progressRef.current,
    images: imagesRef.current,
    background: backgroundRef.current
  });

  return (
    <>
      <LandingBackground
        ref={backgroundRef}
        loaderRef={wrapperRef}
        billboardWrapperRef={billboardWrapperRef}
      />
      <Styled.Wrapper ref={wrapperRef} isClickThrough={isLoaderMotionComplete}>
        {!isLoaderMotionComplete && <LandingImages ref={imagesRef} />}
        <Styled.IntroHeader ref={introHeaderWrapperRef}>
          <StretchedCopy
            ref={introHeaderRef}
            copy={header}
          />
        </Styled.IntroHeader>
        {!isLoaderMotionComplete && (
          <>
            <Styled.SpotifyPresents ref={spotifyPresentsWrapperRef}>
              <Lottie
                ref={spotifyPresentsRef}
                animation={lottieFiles.spotifyPresents}
                autoPlay={false}
                pauseAtFrame={92}
              />
            </Styled.SpotifyPresents>
            <LandingMobileLogos ref={mobileLogosRef} />
            <Styled.Uncover ref={uncoverWrapperRef}>
              <Lottie
                ref={uncoverRef}
                animation={lottieFiles.uncover}
                autoPlay={false}
                pauseAtFrame={95}
                forceContinue={continueUncoverLottie}
              />
            </Styled.Uncover>
            <LandingProgress ref={progressRef} />
          </>
        )}
      </Styled.Wrapper>
    </>
  );
};

LandingLoader.defaultProps = defaultProps;

export default LandingLoader;
