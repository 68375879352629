import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

interface WrapperProps {
  faded?: boolean;
}

export const Wrapper = styled.div`
  opacity: ${({ faded }: WrapperProps) => faded ? 0.35 : 1};
  transition: opacity 200ms ease-in-out;
  overflow: hidden;
`;

export const LottieWrapper = styled.div`
  height: 80%;
`;

export const DOMWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h3`
  ${({ theme: { locale } }) => setTypography('heading5', locale)}
  user-select: none;
`;

export const BottomText = styled.p`
  ${({ theme: { locale } }) => setTypography('small', locale)}
`;

export const Number = styled.p`
  user-select: none;
`;

export const Dash = styled.div`
  display: inline-block;
  width: 100%;
  height: 6rem;
  background-color: ${colors.white};

  ${mediaTablet(`
    height: 10rem;
  `)}
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 6rem;

  svg {
    height: 6rem;

  }

  ${BottomText} {
    margin-right: 20rem;
  }

  ${mediaTablet(`
    svg {
      height: 10rem;
    }
  `)}
`;
