import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
`;

export const Percentage = styled.p`
  ${({ theme: { locale } }) => setTypography('subtitle1', locale)};
  margin-right: 40rem;

  ${mediaTablet(`
    margin-right: 50rem;
  `)}
`;

export const Bar = styled.div`
  width: 150rem;
  height: 4rem;
  background-color: ${colors.roti};

  ${mediaTablet(`
    width: 350rem;
  `)}
`;
