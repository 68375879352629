import styled from 'styled-components';

import { headerCopy, setVh } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

export const BillboardWrapper = styled.div`
  padding-top: calc(env(safe-area-inset-top) + 360rem);
  width: 100%;
  display: flex;
  align-items: flex-end;
  z-index: ${layout.zIndex.landingBillboard};
  flex-shrink: 0;
  position: relative;

  ${mediaTablet(`
    padding-top: calc(env(safe-area-inset-top) + 220rem);
  `)}

  ${mediaDesktop(`
    padding-top: calc(env(safe-area-inset-top) + 160rem);
  `)}
`;

export const Header = styled.p`
  ${headerCopy(setVh(layout.headerFooterHeight.desktop))}
`;

export const RibbonWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: ${layout.zIndex.landingRibbon};
`;
export const ClubhouseSelectorWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: ${layout.zIndex.landingClubhouseSelector};
`;
export const BlankPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: white;
`;
