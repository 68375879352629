import styled from 'styled-components';
import { fullscreen } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export interface ImageProps {
  top: string;
  left: string;
  image: string;
  scale: number;
}

export const Wrapper = styled.div`
  ${fullscreen()}
  opacity: 0.35;
`;

export const Image = styled.div<ImageProps>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  width: 320rem;
  height: 320rem;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  transform: scale(${({ scale }) => scale});

  ${mediaTablet(`
    width: 460rem;
    height: 460rem;
  `)}
`;
