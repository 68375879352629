import React, { useImperativeHandle, useRef } from 'react';
import gsap from 'gsap';

import { ClubhousePageProps } from 'containers/ClubhousePage/ClubhousePage';

import useAssets from './ClubhouseSelectorImages.assets';
import useMotion from './ClubhouseSelectorImages.motion';
import * as Styled from './ClubhouseSelectorImages.styles';

export interface ClubhouseSelectorImagesRef {
  animateIn?: () => gsap.core.Timeline;
}
export interface ClubhouseSelectorImagesProps {
  clubhouse?: ClubhousePageProps['city'];
  hovered?: boolean;
}

const defaultProps: Partial<ClubhouseSelectorImagesProps> = {
  clubhouse: 'seoul',
  hovered: false
};

const ClubhouseSelectorImages = React.forwardRef<ClubhouseSelectorImagesRef, ClubhouseSelectorImagesProps>(
  ({
    clubhouse,
    hovered
  }: ClubhouseSelectorImagesProps, ref) => {
    const mainImageRef = useRef<HTMLDivElement>(null);
    const sequencedImagesRef = useRef<HTMLDivElement[]>([]);
    const whiteFlashRef = useRef<HTMLDivElement>(null);

    const images = useAssets(clubhouse);

    const { animateIn } = useMotion({
      mainImage: mainImageRef,
      sequencedImages: sequencedImagesRef.current,
      whiteFlash: whiteFlashRef.current,
      hovered
    });

    const sequencedImages = images.sequence.map((image, index) => {
      return (
        <Styled.SequencedImage
          key={`SequencedImage-${index}`}
          ref={(ref: HTMLDivElement) => sequencedImagesRef.current[index] = ref}
          background={image}
          zIndex={index + 1}
        />
      );
    })

    useImperativeHandle(
      ref,
      (): ClubhouseSelectorImagesRef => ({
        animateIn: animateIn
      })
    );

    return (
      <Styled.Wrapper>
        <Styled.MainImage
          ref={mainImageRef}
          background={images.main}
        />
        <Styled.SequencedImagesWrapper>
          {sequencedImages}
          <Styled.WhiteFlash ref={whiteFlashRef} />
        </Styled.SequencedImagesWrapper>
      </Styled.Wrapper>
    );
  }
);

ClubhouseSelectorImages.defaultProps = defaultProps;

export default ClubhouseSelectorImages;
