import React, { useEffect, useState, useMemo, useImperativeHandle, useRef } from 'react';
import gsap from 'gsap';
import { useRouter } from 'next/router';

// utils
import { useGlobalStore, useLoaderStore, usePlaylistsStore } from 'store';
import { usePreloadDirectory } from 'u9/hooks';
import { getFormattedPercentage } from 'utils/numbers';

// assets
import AudioManager from 'services/audioManager/audioManager.service';
import { defaultBGMData, SoundData, SOUNDS_DATA } from 'services/audioManager/audioManager.data';

import useMotion from './LandingProgress.motion';
import * as Styled from './LandingProgress.styles';

// interfaces
export interface LandingProgressRef {
  inTl: gsap.core.Timeline;
  outTl: gsap.core.Timeline;
  progress: number;
}

export interface LandingProgressProps { }
const defaultProps: Partial<LandingProgressProps> = {};

const PRELOAD_DIRECTORIES = [
  'assets/images/landing/',
  'assets/images/ui/'
];

const LandingProgress = React.forwardRef<LandingProgressRef, LandingProgressProps>(
  (props, ref) => {
    const { isReady, setReady, isLoaderMotionComplete } = useGlobalStore();
    const { setGlobalLoadProgress } = useLoaderStore(state => state);
    const anthemTrack = usePlaylistsStore(state => state.anthemTrack);

    const progress = usePreloadDirectory(PRELOAD_DIRECTORIES);
    const [audioProgress, setAudioProgress] = useState<number>(0);
    const totalProgress = useMemo(() => (progress + audioProgress) / 2, [progress, audioProgress]);
    const router = useRouter();

    useEffect(() => {
      setGlobalLoadProgress(totalProgress);
    }, [setGlobalLoadProgress, totalProgress])

    const wrapperRef = useRef(null);
    const percentageRef = useRef(null);
    const barRef = useRef(null);

    // audio loading
    useEffect(() => {
      if (isReady || !anthemTrack) return;

      const anthemPreviewSound: SoundData = {
        ...defaultBGMData,
        id: anthemTrack.id,
        file: anthemTrack.previewUrl
      };

      if (anthemTrack.previewUrl) SOUNDS_DATA[anthemTrack.id] = anthemPreviewSound;
      AudioManager.init((progress) => setAudioProgress(Math.floor(progress * 100)), () => setAudioProgress(100));
    }, [anthemTrack, isReady]);

    useEffect(() => {
      if (isLoaderMotionComplete && totalProgress >= 100) {
        setReady(true);
      }
    }, [isLoaderMotionComplete, totalProgress, setReady]);

    const percentage = useMemo(() => Math.floor(totalProgress), [totalProgress]);
    const { inTl, outTl } = useMotion({
      progress: percentage,
      wrapper: wrapperRef.current,
      percentage: percentageRef.current,
      bar: barRef.current
    });

    useImperativeHandle(
      ref,
      (): LandingProgressRef => ({
        inTl, outTl, progress: totalProgress
      })
    );

    return (
      <Styled.Wrapper ref={wrapperRef}>
        <Styled.Percentage ref={percentageRef} >
          {getFormattedPercentage(percentage / 100, router.locale)}
        </Styled.Percentage>
        <Styled.Bar ref={barRef} />
      </Styled.Wrapper>
    );
  }
);

LandingProgress.defaultProps = defaultProps;

export default LandingProgress;
