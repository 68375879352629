import { useEffect, useRef } from 'react';
import gsap from 'gsap';

const useMotion = ({
  progress,
  wrapper,
  percentage,
  bar
}) => {
  const inTl = useRef<gsap.core.Timeline>(null);
  const outTl = useRef<gsap.core.Timeline>(null);

  useEffect(() => {
    if (wrapper) {
      inTl.current = gsap.timeline({ paused: true });
      inTl.current
        .fromTo(
          wrapper,
          { opacity: 0 },
          { opacity: 1, duration: 0.8, ease: 'power4.inOut' }
        );

      outTl.current = gsap.timeline({ paused: true });
      outTl.current
        .to(
          bar,
          {
            transformOrigin: 'top right',
            scaleX: 0,
            duration: 0.8,
            ease: 'power4.inOut'
          }
        )
        .to(
          percentage,
          { x: '400%', duration: 0.8, ease: 'power4.inOut' },
          0
        )
        .to(
          wrapper,
          { opacity: 0, duration: 0.5, ease: 'power4.inOut' },
          0.6
        )
    }
  }, [wrapper, bar, percentage]);

  useEffect(() => {
    if (bar) {
      gsap.to(
        bar,
        {
          transformOrigin: 'top left',
          scaleX: progress / 100,
          duration: 0.1,
          ease: 'power4.inOut'
        }
      )
    }
  }, [progress, bar]);

  return {
    inTl: inTl.current,
    outTl: outTl.current
  }
};

export default useMotion;
