import React, { useRef, useEffect, useImperativeHandle } from 'react';
import gsap from 'gsap';

// components
import { ReactComponent as SvgSpotifyShort } from 'svgs/spotify-circle-transparent.svg';
import { ReactComponent as SvgWorldsShort } from 'svgs/worlds-icon.svg';

// utils
import { isMobileLayout } from 'utils/styles/responsive';

import * as Styled from './LandingMobileLogos.styles';

export interface LandingMobileLogosRef {
  timeline: gsap.core.Timeline
}

export interface LandingMobileLogosProps { }
const defaultProps: Partial<LandingMobileLogosProps> = {};

const LandingMobileLogos = React.forwardRef<LandingMobileLogosRef, LandingMobileLogosProps>(
  (props, ref) => {
    const spotify = useRef(null);
    const worlds = useRef(null);
    const timelineRef = useRef(null);

    useEffect(() => {
      if (spotify.current && worlds.current) {
        timelineRef.current = gsap.timeline({ paused: true });
        timelineRef.current
          .fromTo(
            [spotify.current, worlds.current],
            { autoAlpha: 0 },
            {
              autoAlpha: 1,
              duration: 0.8,
              ease: 'power4.inOut',
              stagger: 0.1
            },
          )
      }
    }, [spotify, worlds]);

    useImperativeHandle(
      ref,
      (): LandingMobileLogosRef => ({
        timeline: timelineRef.current || { play: () => null }
      })
    )

    return isMobileLayout() && (
      <Styled.Wrapper>
        <Styled.SvgWrapper ref={spotify}>
          <SvgSpotifyShort />
        </Styled.SvgWrapper>
        <Styled.SvgWrapper ref={worlds}>
          <SvgWorldsShort />
        </Styled.SvgWrapper>
      </Styled.Wrapper>
    );
  }
);

LandingMobileLogos.displayName = 'LandingMobileLogos';
LandingMobileLogos.defaultProps = defaultProps;

export default LandingMobileLogos;
