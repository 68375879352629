import { MutableRefObject, useEffect, useRef } from 'react';
import gsap from 'gsap';

import { useGlobalStore } from 'store';
import { isStorybook } from 'u9/utils/platform';
import { isMobileLayout } from 'utils/styles/responsive';
import { useWindowSize } from 'u9/hooks';

interface MotionProps {
  wrapperRef: MutableRefObject<HTMLDivElement>;
  blackRef: MutableRefObject<HTMLDivElement>;
  whiteRef: MutableRefObject<HTMLDivElement>;
  blueRef: MutableRefObject<HTMLDivElement>;
  loaderRef: MutableRefObject<HTMLDivElement>;
  billboardWrapperRef: MutableRefObject<HTMLDivElement>;
}

const useMotion = ({
  wrapperRef,
  blackRef,
  whiteRef,
  blueRef,
  loaderRef,
  billboardWrapperRef
}: MotionProps) => {
  const windowSize = useWindowSize();
  const { isLoaderMotionComplete } = useGlobalStore(state => state);

  const inTl = useRef<gsap.core.Timeline>(null);
  const outTl = useRef<gsap.core.Timeline>(null);

  const scrollTarget = useRef<number>(0);

  useEffect(() => {
    scrollTarget.current = billboardWrapperRef.current?.getBoundingClientRect().bottom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billboardWrapperRef.current, windowSize]);

  useEffect(() => {
    const black = blackRef.current;
    const white = whiteRef.current;
    const blue = blueRef.current;

    if (!inTl.current && black && white && blue) {
      gsap.set(
        [white, blue],
        { transformOrigin: 'center', rotate: 45 }
      );

      inTl.current = gsap.timeline({ paused: true });
      inTl.current
        .to(
          [white, blue],
          {
            scaleY: isMobileLayout() ? 4 : 8,
            duration: 4,
            ease: 'power4.inOut'
          }
        );

      // Storybook testing
      if (isStorybook()) {
        inTl.current.play(0);
        outTl.current.delay(5).play();
      }
    }
  }, [blackRef, whiteRef, blueRef]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const loader = loaderRef.current;
    const billboardWrapper = billboardWrapperRef.current;

    const black = blackRef.current;
    const white = whiteRef.current;
    const blue = blueRef.current;

    if (!outTl.current && wrapper && loader && billboardWrapper) {
      const target = { progress: 0 };

      outTl.current = gsap.timeline({ paused: true });
      outTl.current
        .set([black, white, blue], { transform: '', top: 0, left: 0 })
        .set([black, white], { opacity: 0 })
        .to(
          target,
          {
            progress: 1,
            onUpdate: () => {
              const value = `inset(0 0 ${target.progress * (windowSize.height - scrollTarget.current)}px 0)`;
              wrapperRef.current.style.clipPath = value;
              loaderRef.current.style.clipPath = value;
            },
            duration: 1.2,
            ease: 'power4.inOut'
          }
        )
        .set(wrapper, { zIndex: 0 });
    }
  }, [wrapperRef, loaderRef, billboardWrapperRef, windowSize, blackRef, whiteRef, blueRef, scrollTarget]);

  useEffect(() => {
    if (isLoaderMotionComplete) {
      const value = `inset(0 0 ${windowSize.height - scrollTarget.current}px 0)`;
      wrapperRef.current.style.clipPath = value;
      loaderRef.current.style.clipPath = value;
    }
  }, [windowSize, billboardWrapperRef, wrapperRef, isLoaderMotionComplete, loaderRef, scrollTarget]);

  useEffect(() => {
    return () => {
      if (inTl.current) {
        inTl.current.kill();
      }

      if (outTl.current) {
        outTl.current.kill();
      }
    };
  }, []);

  return { inTl, outTl };
};

export default useMotion;
