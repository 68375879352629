import styled from 'styled-components';
import { layout } from 'utils/styles/theme';
import { fullscreen, setVh, headerCopy } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

export const Wrapper = styled.div<{ isClickThrough: boolean }>`
  ${fullscreen()}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 400rem 0;
  clip-path: inset(0 0 0 0);
  z-index: 4;

  ${({ isClickThrough }) => isClickThrough ? 'pointer-events: none;' : ''}

  ${mediaTablet(`
    justify-content: flex-end;
  `)}
`;

export const IntroHeader = styled.div`
  ${headerCopy(setVh(layout.headerFooterHeight.desktop))}
  left: 50%;
  text-align: center;

  ${({ theme: { locale } }) => locale === 'es' ? `
    padding-left: ${layout.headerFooterPadding.mobile}rem;
    padding-right: ${layout.headerFooterPadding.mobile}rem;
    transform: translate(-50%, -25%);
  ` : `
    transform: translateX(-50%);
  `}

  ${mediaTablet(`
    padding: 0;
    ${headerCopy('0', setVh(layout.headerFooterHeight.mobile), 60)};
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
  `)}
`;

export const SpotifyPresents = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  transform: translate(-50%, -50%);
  z-index: 5;

  ${mediaTablet(`
    width: 100%;
  `)}
`;

export const Uncover = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110%;
  z-index: 5;
  opacity: 0;

  ${mediaTablet(`
    width: 100%;
  `)}
`;
